<template>
   <div>
     <nav-header @foundationchangedata="foundationhandlerdata"></nav-header>
     <div class="foundation envCon">
       <div :class="lang">
         <div class="logo feature">
           <img src="/img/foundation/foundation.png" alt="">
           <p>
             {{$t('foundation.title')}}
           </p>
         </div>
         <div class="f-menu feature" >
          <div v-if="this.$i18n.locale=='zh'">
              <ul class="f-menu-firstul">
              <!-- <li v-for="(item,index) in navList" :key="item.id" @click.stop="Navscroll(item.name)">
                {{$t('foundation.'+item.text)}}
                <span class="delta" v-if="index == 0"></span>
              </li> -->
              <li @click="menuCLick">
                  乡村振兴
                  <span class="delta" :class="{'transdelta':deltaFlag}"></span>
              </li>
              <li @click.stop="Navscroll('ky')">
                  抗疫救灾
              </li>
              <li @click.stop="Navscroll('zg')">
                SOHO中国助学金
              
              </li>
              <li @click.stop="Navscroll('md')">
                美德教育
              
              </li >
              <li @click.stop="Navscroll('zx')">
                西部助学
                
              </li>
              <li @click.stop="Navscroll('xx')">
                特奥融合学校
                
              </li>
            </ul>
            <transition name="fade">
              <ul class="f-menu-seul"  v-show="menuFlag">
                <li @click.stop="Navscroll('yz')"> 养正幼儿园</li>
                <li @click.stop="Navscroll('carbon')">零碳图书馆</li>
                <li @click.stop="Navscroll('bcy')">百草园</li>
                <li @click.stop="Navscroll('yjr')">一家人餐厅</li>
                <li @click.stop="Navscroll('ny')">助力环境保护</li>
                <li style="opacity: 0;">助学及图书捐赠</li>
               
              </ul>
            </transition>
          </div>
          <div v-if="this.$i18n.locale=='en'">
            <ul class="f-menu-firstul">
              <!-- <li v-for="(item,index) in navList" :key="item.id" @click.stop="Navscroll(item.name)">
                {{$t('foundation.'+item.text)}}
                <span class="delta" v-if="index == 0"></span>
              </li> -->
              <li @click="menuCLick">
                Rural Revitalization
                  <span class="delta" :class="{'transdelta':deltaFlag}"></span>
              </li>
              <li @click.stop="Navscroll('ky')">
                Anti-epidemic Work and Disaster Relief
              </li>
              <li @click.stop="Navscroll('zg')">
                SOHO China Scholarships
              
              </li>
              <li @click.stop="Navscroll('md')">
                Virtue Education
              
              </li >
              <li @click.stop="Navscroll('zx')">
                Education aid in Western China
                
              </li>
              <li @click.stop="Navscroll('xx')">
                Special Olympics Unified School
                
              </li>
            </ul>
            <transition name="fade">
              <ul class="f-menu-seul"  v-show="menuFlag">
                <li @click.stop="Navscroll('yz')"> Yang Zheng Kindergarten</li>
                <li @click.stop="Navscroll('carbon')">Zero Carbon Library</li>
                <li @click.stop="Navscroll('bcy')">Bai Cao Garden</li>
                <li @click.stop="Navscroll('yjr')">We are Family Restaurant</li>
                <li @click.stop="Navscroll('ny')">Environmental Protection</li>
                <li style="opacity: 0;">助学及图书捐赠</li>
              </ul>
            </transition>
          </div>
         </div>
         <div class="container" >
          <div class="children feature w" id="yz">
             <h4>  {{$t('foundation.kindergartentitle')}}</h4>
             <div class="children-content">
               <div class="children-content-left">
                 <p>{{foundation_content.childContent}}</p>
               </div>
               <div class="children-content-right">
                 <span class="money" v-if="this.$i18n.locale=='en'">RMB</span>
                 <span class="num">{{foundation_content.childnum}}</span>
                 <span class="text">{{$t('foundation.kindrengartenunit')}}</span>
                 <span class="chinaUni-content-right-spandetail">{{$t('foundation.kindergartendetail')}}</span>
               </div>
             </div>
             <div class="fbanner">
               <swiper v-bind:options="swiperOption" >
                 <swiper-slide v-for="(item,index) in foundation_content.childbannerlist" v-bind:key="index">
                   <img :src="item.url">
                 </swiper-slide>

               </swiper>

               <!-- Add Pagination -->
               <div class="swiper-pagination"></div>
             </div>

           </div>
           <div class=" feature w carbon" id="carbon">
             <h4> {{$t('foundation.carbontitle')}}</h4>
             <div class="children-content">
               <div class="children-content-left">
                 <p>{{foundation_content.carbonObj.carbonLibraryContent}}</p>
               </div>
               <div class="children-content-right">
                 <span class="money" v-if="this.$i18n.locale=='en'">RMB</span>
                 <span class="num">{{foundation_content.carbonObj.carbonLibraryNum}}</span>
                 <span class="text">{{$t('foundation.kindrengartenunit')}}</span>
                 <span class="chinaUni-content-right-spandetail">{{$t('foundation.carbondtag')}}</span>
               </div>
             </div>
             <div class="fbanner">
               <swiper v-bind:options="swiperOptionC" >
                 <swiper-slide v-for="(item,index) in foundation_content.carbonObj.carbonLibraryList" v-bind:key="index">
                   <img :src="item.url">
                 </swiper-slide>

               </swiper>

               <!-- Add Pagination -->
               <div class="swiper-paginationC"></div>
             </div>

           </div>

           <!-- 百草园 -->
           <div  class="feature w herbar west" id="bcy">
            <h4>{{$t('foundation.herbaltitle')}}</h4>
             <p>{{foundation_content.baiGerdenObj.content}}</p>
            <!-- <div class="herbarimg-container">
              <div class="herbarimg-left">
                <img src="https://sohochina.oss-cn-beijing.aliyuncs.com/ESG/garden.png" alt="">
              </div>
              <div class="herbarimg-right">
                <img src="https://sohochina.oss-cn-beijing.aliyuncs.com/ESG/childrens.png" alt="">
              </div>
             
            </div> -->
            <div class="herbarimg">
              <swiper v-bind:options="swiperOptionF" >
                 <swiper-slide v-for="(item,index) in foundation_content.baiGerdenObj.imgList" v-bind:key="index">
                   <img :src="item.url">
                 </swiper-slide>

               </swiper>

               <!-- Add Pagination -->
               <div class="swiper-paginationF"></div>
            </div>

           </div>
               <!-- 一家人 -->
            <div class="family feature w" id="yjr">
             <h4>{{$t('foundation.familytilte')}}</h4>
             <p>{{foundation_content.familycontent}}</p>
             <div class="ybanner2">
               <swiper v-bind:options="swiperOption2" class="pubCon">
                 <swiper-slide v-for="(item,index) in foundation_content.familyimgList" v-bind:key="index">
                   <img v-bind:src="item.url">
                 </swiper-slide>
               </swiper>
               <div class="swiper-pagination2"></div><!--分页器。如果放置在swiper-container外面，需要自定义样式。-->
             </div>
           </div>

            <!-- 助力环境保护 -->
            <div class="source w" id="ny">
             <h4>{{$t('foundation.envtitle')}}</h4>
             <p>{{foundation_content.envProtectionObj.content}}</p>
             <div class="fbanner">
               <swiper v-bind:options="swiperOptionE" >
                 <swiper-slide v-for="(item,index) in foundation_content.envProtectionObj.imgList" v-bind:key="index">
                   <img :src="item.url">
                 </swiper-slide>

               </swiper>

               <!-- Add Pagination -->
               <div class="swiper-paginationE"></div>
             </div>
           </div>
           <!-- 救灾 -->
           <div class="provide feature w" id="ky">
             <h4>{{$t('foundation.disastertitle')}}</h4>
             <ul>
               <li v-for="(item,index) in foundation_content.disasterList" :key="index">
                 <div class="left">
                   <div class="provide-left-top">
                     <span class="num">{{item.disasternum}}</span>
                     <span class="text" v-if="index !=1"> {{$t('foundation.disasterunit1')}}</span>
                     <span class="text" v-if="index ==1"> {{$t('foundation.disasterunit')}}</span>
                     <span class="detail">{{item.disasterdetail}}</span>
                     <p>{{item.disastercontent}}</p>
                   </div>
                 </div>
               </li>
             </ul>

           </div>
           <!-- SOHO中国助学 -->
           <div class="chinaUni feature w" id="zg">

             <h4>{{$t('foundation.studenttitle')}}</h4>
             <div class="chinaUni-content">
               <div class="chinaUni-content-left">
                 <p>{{foundation_content.helpstudycontent}}</p>
               </div>
               <div class="chinaUni-content-right">
                 <span class="num">{{foundation_content.helpstudpeople}}</span>
                 <span class="text">{{$t('foundation.studentunit')}}</span>
                 <span class="chinaUni-content-right-spandetail1">{{$t('foundation.studentDetail')}}</span>
               </div>
             </div>
             <div class="cbanner1">
               <swiper v-bind:options="swiperOption1" class="pubCon">
                 <swiper-slide v-for="(item,index) in foundation_content.studyimgList" v-bind:key="index">
                   <img v-bind:src="item.url">
                 </swiper-slide>

               </swiper>
               <div class="swiper-pagination1"></div><!--分页器。如果放置在swiper-container外面，需要自定义样式。-->

             </div>
           </div>
          
           <!--美德培训 -->
           <div class="chinaUni feature vistuecontent w" id="md">
             <h4>{{$t('foundation.virtuetitle')}}</h4>
             <div class="chinaUni-content">
               <div class="chinaUni-content-left">
                 <p>{{foundation_content.virtuecontent}}</p>
                 <p  style="margin-top: 20px">{{foundation_content.virtuecontdetail}}</p>
               </div>
               <div class="chinaUni-content-right">
                 <div >
                   <span class="num vistue">{{foundation_content.virtuepelple}}</span>
                   <span class="chinaUni-content-right-spandetail1 margin6" style="text-align: left;" >{{$t('foundation.virtuedetail')}}</span>
                 </div>

               </div>
             </div>
             <div class="cbanner1">
               <swiper v-bind:options="swiperOption3" class="pubCon">
                 <swiper-slide v-for="(item,index) in foundation_content.virtueimgList" v-bind:key="index">
                   <img :src="item.url">
                 </swiper-slide>

               </swiper>
               <div class="swiper-pagination3"></div><!--分页器。如果放置在swiper-container外面，需要自定义样式。-->

             </div>
           </div>
           <!-- 西部助学 -->
           <div class="west feature w" id="zx">
             <h4>{{$t('foundation.westtitle')}}</h4>
             <ul>
               <li  class="west-margin" v-for="(item,index) in foundation_content.westList" :key="index">
                 <div class="left">
                   <div class="provide-left-top">
                     <span class="num">{{item.westnum}}</span>
                     <span class="text" v-if="index !=2"> {{$t('foundation.westunit')}}</span>
                     <p>{{item.westcontent}}</p>
                   </div>
                 </div>
               </li>
             </ul>

           </div>
       
           <!-- 学校 -->
           <div class="sch w" id="xx">
             <h4>{{$t('foundation.scholltitle')}}</h4>
             <p>{{foundation_content.specialscholl}}</p>
           </div>
         </div>
       </div>


     </div>
   </div>

</template>
<script>
import NavHeader from "../components/Header"
import { getdataInfo } from "../api/home";
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import {mapState} from 'vuex'
import 'swiper/css/swiper.css'
export default {
  name: "foundation",
  data(){
    return{
      isFixed:false,
      id: 3,
      menuFlag:false,
      deltaFlag:false,
      pageType: 'foundation',
      foundation_content:{
          childContent: null,
          childbannerlist: [],
          childnum: null,
          carbonObj:{
            carbonLibraryNum:null,
            carbonLibraryContent:'',
            carbonLibraryList:[],
          },
          envProtectionObj:{
            content:'',
            imgList:[]
          },
          baiGerdenObj:{
            content:'',
            imgList:[]
          },
          helpstudpeople: 12,
          helpstudycontent: '',
          enerycontent: '',
          virtuepelple: 222,
          virtuecontent: '',
          virtuecontdetail: '',
          familycontent: '',
          specialscholl: '',
          studyimgList: [],
          eneryimgList: [],
          virtueimgList: [],
          familyimgList: [],
          disasterList: [
          ],
          westList: [
          ]
      },
      eneryimgList: [],
      navList:[
        {
          id:"9",
          text:"nav9",
          name: 'carbon'
        },
        {
          id:"1",
          text:"nav1",
          name:'yz'

        },
        {
          id:"2",
          text:"nav2",
          name:'ky'

        },

        {
          id:"4",
          text:"nav4",
          name:'zg'

        },
        {
          id:"5",
          text:"nav5",
          name:'ny'
        },

        {
          id:"8",
          text:"nav8",
          name: 'md'
        },
        {
          id:"3",
          text:"nav3",
          name:'zx'

        },
        {
          id:"6",
          text:"nav6",
          name: 'yjr'
        },

        {
          id:"7",
          text:"nav7",
          name: 'xx'
        },



      ],
      envvList:[
        {
          id:"1",
          imgUrl:"https://sohochina.oss-cn-beijing.aliyuncs.com/ESG/e6.jpg"
        },
        {
          id:"2",
          imgUrl:"https://sohochina.oss-cn-beijing.aliyuncs.com/ESG/e2.jpg"
        },
        {
          id:"3",
          imgUrl:"https://sohochina.oss-cn-beijing.aliyuncs.com/ESG/e3.jpg"
        },
        {
          id:"4",
          imgUrl:"https://sohochina.oss-cn-beijing.aliyuncs.com/ESG/e4.jpg"
        },
        {
          id:"5",
          imgUrl:"https://sohochina.oss-cn-beijing.aliyuncs.com/ESG/e5.jpg"
        }
      ],
      familyList:[
        {
          id:"1",
          imgUrl:"/img/foundation/family/f1.JPG"
        },
        {
          id:"2",
          imgUrl:"/img/foundation/family/f2.JPG"
        },
        {
          id:"3",
          imgUrl:"/img/foundation/family/f3.JPG"
        },
        {
          id:"4",
          imgUrl:"/img/foundation/family/f4.JPG"
        },
        {
          id:"5",
          imgUrl:"/img/foundation/family/f5.JPG"
        }
      ],
      chinaList:[
        {
          id:"1",
          imgUrl:"/img/foundation/zx/zx1.jpg"
        },
        {
          id:"2",
          imgUrl:"/img/foundation/zx/zx2.jpg"
        },
        {
          id:"3",
          imgUrl:"/img/foundation/zx/zx3.jpg"
        },
        {
          id:"3",
          imgUrl:"/img/foundation/zx/zx4.jpg"
        }
      ],
      carbonList:[
      {
          id:"1",
          imgUrl:"https://sohochina.oss-cn-beijing.aliyuncs.com/ESG/lib1.jpg"
        },
        {
          id:"2",
          imgUrl:"https://sohochina.oss-cn-beijing.aliyuncs.com/ESG/lib2.jpg"
        },

        {
          id:"5",
          imgUrl:"https://sohochina.oss-cn-beijing.aliyuncs.com/ESG/lib3.jpg"
        },
        {
          id:"6",
          imgUrl:"https://sohochina.oss-cn-beijing.aliyuncs.com/ESG/lib4.jpg"
        },
        {
          id:"7",
          imgUrl:"https://sohochina.oss-cn-beijing.aliyuncs.com/ESG/lib5.jpg"
        }
      ],
      baiList:[
        {
          id:"5",
          imgUrl:"https://sohochina.oss-cn-beijing.aliyuncs.com/ESG/bcy1.jpg"   
        },
        {
          id:"6",
          // imgUrl:"https://sohochina.oss-cn-beijing.aliyuncs.com/ESG/bcy2.jpg",
          imgUrl:"https://sohochina.oss-cn-beijing.aliyuncs.com/ESG/bcy5.jpg"
        },
        {
          id:"7",
          
          imgUrl:"https://sohochina.oss-cn-beijing.aliyuncs.com/ESG/bcy3.jpg"
        },
        {
          id:"8",
          imgUrl:"https://sohochina.oss-cn-beijing.aliyuncs.com/ESG/bcy7.jpg"
        },
        {
          id:"9",
          imgUrl:"https://sohochina.oss-cn-beijing.aliyuncs.com/ESG/bcy8.jpg"
        }

      ],
      vistueList:[
        {
          id:"1",
          imgUrl:"/img/carbon.png"
        },
        {
          id:"2",
          imgUrl:"/img/foundation/vistue/m2.jpg"
        },
        {
          id:"3",
          imgUrl:"/img/foundation/vistue/m3.jpg"
        },
        {
          id:"4",
          imgUrl:"/img/foundation/vistue/m4.jpg"
        },
        {
          id:"5",
          imgUrl:"/img/foundation/vistue/m5.jpg"
        },
        {
          id:"6",
          imgUrl:"/img/foundation/vistue/m6.jpg"
        },
        {
          id:"7",
          imgUrl:"/img/foundation/vistue/m7.jpg"
        },
        {
          id:"8",
          imgUrl:"/img/foundation/vistue/m8.jpg"
        },
        {
          id:"9",
          imgUrl:"/img/foundation/vistue/m9.jpg"
        },
      ],
      childrenList:[
        {
          id:"1",
          imgUrl:"/img/foundation/children/c1.jpg"
        },
        {
          id:"2",
          imgUrl:"/img/foundation/children/c2.jpg"
        },
        {
          id:"3",
          imgUrl:"/img/foundation/children/c3.jpg"
        },
        {
          id:"4",
          imgUrl:"/img/foundation/children/c4.jpg"
        },
        {
          id:"5",
          imgUrl:"/img/foundation/children/c5.JPG"
        },
        {
          id:"6",
          imgUrl:"/img/foundation/children/c6.JPG"
        },
        {
          id:"7",
          imgUrl:"/img/foundation/children/c7.JPG"
        },
        {
          id:"8",
          imgUrl:"/img/foundation/children/c8.jpg"
        },
        {
          id:"9",
          imgUrl:"/img/foundation/children/c9.jpg"
        },
        {
          id:"10",
          imgUrl:"/img/foundation/children/c10.jpg"
        },
      ],
      swiperOption:{
        loop: true, // 循环模式选项
        autoplay:true,
        delay: 1000,
        pagination: {
          el: '.swiper-pagination',
        }
      },
      swiperOption1:{
        loop: true, // 循环模式选项
        autoplay:true,
        delay: 1000,
        pagination: {
          el: '.swiper-pagination1',
        }
      },
      swiperOption2:{
        loop: true, // 循环模式选项
        autoplay:true,
        delay: 1000,
        pagination: {
          el: '.swiper-pagination2',
        }
      },
      swiperOption3:{
        loop: true, // 循环模式选项
        autoplay:true,
        delay: 1000,
        pagination: {
          el: '.swiper-pagination3',
        }
      },
      swiperOptionC:{
        loop: true, // 循环模式选项
        autoplay:true,
        delay: 1000,
        pagination: {
          el: '.swiper-paginationC',
        }
      },
      swiperOptionE:{
        loop: true, // 循环模式选项
        autoplay:true,
        delay: 1000,
        pagination: {
          el: '.swiper-paginationE',
        }
      },
      swiperOptionF:{
        loop: true, // 循环模式选项
        autoplay:true,
        delay: 1000,
        pagination: {
          el: '.swiper-paginationF',
        }
      },
    }
  },

  components:{
    Swiper,
    SwiperSlide,
    NavHeader
  },
  computed:{
   ...mapState({
     lang:state => state.lang
   })
  },
  mounted() {
    const staggeringOption = {
      delay: 300,
      distance: "50px",
      duration: 500,
      easing: "ease-in-out",
      origin: "bottom",
    };

    this.$scroll().reveal(".feature", { ...staggeringOption, interval: 350 });
    this.getdata()
    // window.addEventListener('scroll',this.initHeight)
  },
  methods:{
    // initHeight(){
    //     let scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
    //     this.isFixed = scrollTop > 500? true:false;
    // },
    menuCLick(){
      this.menuFlag = !this.menuFlag
      this.deltaFlag = ! this.deltaFlag
    },
    async getdata() {
      const data = await getdataInfo({ id: this.id, pageType: this.pageType })
      const zh_envdata= JSON.parse(data.esgContent.cnContent)
      const en_envdata= JSON.parse(data.esgContent.enContent)
      if(this.$i18n.locale=='zh'){
        this.foundation_content = zh_envdata
         this.foundation_content.disasterList = zh_envdata.disasterList.reverse()
      }else {
        this.foundation_content = en_envdata
        this.foundation_content.disasterList = en_envdata.disasterList.reverse()
        this.foundation_content['childbannerlist'] = zh_envdata.childbannerlist
        this.foundation_content.carbonObj['carbonLibraryList'] = zh_envdata.carbonObj.carbonLibraryList
        this.foundation_content.baiGerdenObj['imgList'] = zh_envdata.baiGerdenObj.imgList
        this.foundation_content['familyimgList'] = zh_envdata.familyimgList
        this.foundation_content['studyimgList'] = zh_envdata.studyimgList
        this.foundation_content['virtueimgList'] = zh_envdata.virtueimgList
        this.foundation_content.envProtectionObj['imgList'] = zh_envdata.envProtectionObj.imgList


      
      }
    },
    Navscroll(id){
      document.querySelector(`#${id}`).scrollIntoView({behavior: "smooth",block: "start", inline: "nearest"});
    },
    foundationhandlerdata(){
      this.getdata()
    }
  },
  // destroyed() {
  //   window.removeEventListener('scroll',this.initHeight,false)
  // }
}
</script>

<style lang="scss">

.swiper-pagination-bullet{
  width:12px;
  height: 12px;
  background-color: grey;
  margin-left: 10px;
}
.swiper-pagination{
  width: 1080px;
  margin: 0 auto;
  text-align: center;
  margin-top: 10px;
}
.swiper-pagination1{
  width: 1080px;
  margin: 0 auto;
  text-align: center;
  margin-top: 10px;
}
.swiper-pagination2{
  width: 1080px;
  margin: 0 auto;
  text-align: center;
  margin-top: 10px;
}
.swiper-pagination3{
  width: 1080px;
  margin: 0 auto;
  text-align: center;
  margin-top: 10px;
}
.swiper-paginationC{
  width: 1080px;
  margin: 0 auto;
  text-align: center;
  margin-top: 10px;
}
.swiper-paginationE{
  width: 1080px;
  margin: 0 auto;
  text-align: center;
  margin-top: 10px;
}
.foundation{
  color:#353535;
  padding: 100px 0px;
  .en .money{
    font-family: "BarlowCondensed-bold";
    font-size: 32px;
  }
  .isFixed{
    position: fixed;
    top:-70px;
    width:1086px;
    z-index: 99999;
  }
  .zh p{
    line-height: 1.7;
    letter-spacing: 2px;
  }
  p{
    line-height: 1.7;
    letter-spacing: 2px;
  }
  .carbon{
    padding-top: 140px;
  }
  .en p{
    font-size:22px;
    font-family: "Barlow-Regular";
    line-height: 30px;
    text-align: justify;
    letter-spacing: 0px;
  }
  .logo{
    text-align: center;
  }
   img{
    width:232px;
    height: 130px;
    margin-bottom: 20px;
  }
  .logo p{
    font-size: 28px;
    padding: 0 100px;

  }
  .en .logo p{
    font-family: "BarlowCondensed-bold";
    padding: 0;
    text-align:justify;
    line-height: 34px;
  }

  .f-menu {
    padding-bottom: 120px;
    position: relative;
    margin-top: 70px;
  }
    .fade-enter-active, .fade-leave-active {
    transition: all 0.5s;
    padding-left: 0;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
    transform: translateX(10px);

  }
  .f-menu ul{
    background-color: #f1f1f1;
    height: 52px;
    line-height: 52px;
    width: 100%;
    display: flex;
    font-size: 16px;
    color: #727274;
    padding: 0 30px;
    box-sizing: border-box;
    justify-content: space-between;
    
  }
  .f-menu .f-menu-seul{
    position:absolute;
    left: 0;
    top: 52px;
    background-color: #fff;
    // border-bottom: 1px solid #808080;
  }

  .f-menu .delta{
    display: inline-block;
    width: 0;
    height: 0;
    border: 6px solid transparent;
   
    border-top-color: #808080;
  
    border-bottom: none;
   
    transition: all 0.5s ease 0s;
    margin-left: 3px;
    margin-bottom: 3px;
    /*设置动画效果  0.5秒完成动画 ease(逐渐变慢)*/
  }
  .f-menu ul .transdelta{
    transform: rotate(-180deg);/*旋转180度 */
  }
  .en .f-menu ul{
    display: block;
    flex-wrap:wrap;
    line-height:normal;
    height: 128px;
    font-family: "BarlowCondensed-bold";
    box-sizing: border-box;
    text-align: center;
  }
  .en .f-menu li {
    float: left;
    justify-content:initial;
    align-items:initial;
    font-family: "BarlowCondensed-bold";
    font-size: 18px;
   padding-top: 25px;
    padding-left: 49px;

  }


  .f-menu li{
    cursor: pointer;

  }


  /* 幼儿园 */
  h3{
    font-size: 62px;
    color:rgb(34, 195, 173);
    font-weight: bold;
    text-align: center;
    padding-bottom: 60px;
  }
  h4{
    font-size: 62px;
    color:rgb(34, 195, 173);
    font-weight: bold;
    text-align: center;
    padding-bottom: 60px;
  }
  .en h4{
    font-size: 46px;
    font-family: "BarlowCondensed-bold";
  }
  .fbanner{
    width: 100%;
    margin-top: 60px;
    box-sizing: border-box;
  }
  .fbanner img{
    width: 1086px;
    height: 725px;
  }
  .cbanner1{
    width: 100%;
    margin-top: 60px;
    box-sizing: border-box;
  }

  .cbanner1 img{
    width: 1086px;
    height: 725px;
  }
  .ybanner2{
    width:100%;
    margin-top: 60px;
    box-sizing: border-box;

  }
  .ybanner2 img{
    width: 1086px;
    height: 725px;
  }
  .margin6{
    margin-left: 6px;
  }

  .children-content{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  .children-content-left{
    width: 80%;
  }
  .en  .children-content-left{
    width: 81%;
  }
  .children-content p{
    font-size: 22px;
    text-align: justify;
  }
  .children-content-right{
    width: 20%;
    margin-left: 70px;
    box-sizing: border-box;
    margin-top: -18px;

  }
  .en .children-content-right{
    width: 15%;
  }
  .en .children-content-right{
    text-align: left;
  }

  .children-content-right .num{
    font-size: 86px;
    font-family:"BarlowCondensed-bold";
    color:rgb(53, 53, 53);
  }
  .children-content-right .text{
    font-size: 32px;
    color:rgb(53, 53, 53);
  }
  .en .children-content-right .text{
    font-family: "BarlowCondensed-bold";
  }

  /* 抗争救灾 */
  .provide{
    margin-top: 130px;
    padding-bottom:0px;
  }
  .provide ul{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }
  .provide p{
    font-size: 22px;
  }

  .provide ul li{
    width:50%;
    box-sizing: border-box;
    padding-right: 62px;
    padding-top: 20px;
  }
  .provide ul li:nth-child(2n){
    text-align: left;
    padding-left: 0px;
    padding-right: 0px;
  }

  .num{
    font-size: 70px;
    color:rgb(34, 195, 173);
    font-family: "BarlowCondensed-bold";
    text-align: center;
    letter-spacing: 2px;

  }
  .en {
    font-size: 70px;
    font-family: "BarlowCondensed-bold";
  }

  .text{
    font-size: 32px;
    color:rgb(34, 195, 173);
  }
.en .text{
    font-size: 26px;
    font-family: "BarlowCondensed-bold";
  }

  .detail{
    display: inline-block;
    font-size: 30px;
    margin-left: 20px;
    color: #353535;
  }
  .en .detail{
    font-size: 26px;
    font-family: "BarlowCondensed-bold";
  }


  .west{
    margin-top: 130px;
  }

  .west ul{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }

  .west ul li{
    width: 50%;
    display: flex;
    box-sizing: border-box;
    padding-right: 52px;
  }
  .west ul li:nth-child(2n){
    padding-left: 110px;
    padding-right: 0;
  }
  .west ul li:nth-child(n+3){
    margin-top: 20px;
  }
  .west p{
    font-size: 22px;
    text-align: justify;
  }
  .west-margin{
    margin-top:0px;
  }



  /* 中国助学 */

  .chinaUni{
    margin-top: 130px;
  }
  .chinaUni-content{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  .chinaUni-content-left{
    width: 80%;
  }
  .chinaUni-content p{
    font-size: 22px;
    text-align: justify;
  }
  .chinaUni .num{
    font-size: 86px;
    color:rgb(53, 53, 53);
    font-weight: bold;
    text-align: center;
    padding-bottom: 60px;
  }
  .en .chinaUni .num{
    font-size: 80px;
  }
  .chinaUni .text{
    font-size: 32px;
    color:rgb(53, 53, 53);

  }
  .chinaUni-content-right{
    width: 20%;
    margin-left: 70px;
    box-sizing: border-box;
    text-align: right;
    margin-top: -18px;
  }
  .en .chinaUni-content-right{
    text-align: left;
    width: 17%;
  }
  .chinaUni-content-right-spandetail{
    font-size: 20px;
    display: block;
    margin-top: -14px;
  }
  .en .chinaUni .chinaUni-content-right .vistue{
    color: #22c3ad;
  }
  .en  .chinaUni-content-right-spandetail{
    font-size: 20px;
    display: block;
    text-align: left;
    font-family: "BarlowCondensed-bold";
  }
  .chinaUni-content-right-spandetail1{
    font-size: 20px;
    display: block;
    margin-right: 0px;
    margin-top: -10px;
  }
  .en  .chinaUni-content-right-spandetail1{
    font-family: "BarlowCondensed-bold";
  }




  .chinaUni .banner img{
    width: 100%;
    height: 600px;
  }


  

  /* 一家人 */
  .family{
    margin-top: 130px;
  }
  .family p{
    font-size: 22px;
    text-align: justify;
  }

  .source{
    margin-top: 130px;
  }
  .source img{
    margin-top: 0px;
    display: block;
    width: 100%;
    height: 725px;
  }

  .source p{
    font-size: 22px;
    text-align: justify;
  }
  .sch{
    margin-top: 130px;
  }
  .sch img{
    margin-top: 40px;
    display: block;
    width: 100%;
    height: 700px;
  }

  .sch p{
    font-size: 22px;
    text-align: justify;
  }
      // 百草园
  .herbar{
    // margin-top: 130px;
    .herbarimg{
      text-align: center;
      img{
      display: block;
      width: 100%;
      height: auto;
      margin-top: 60px;
    }
    }

  }

  // .herbarimg-left{
  //   width: 49%;
  // }
  // .herbarimg-right{
  //   width: 49%;
  // }
  // .herbarimg-container img{
  //   display: block;
  //   width: 100%;
  //   height: auto;
  // }
}

@media (max-width: 640px) {
  .swiper-paginationC{
    width: 100%;

  }
  .swiper-pagination{
    width: 100%;

  }
  .swiper-pagination1{
    width:100%;

  }
  .swiper-pagination2{
    width:100%;

  }
  .swiper-pagination3{
    width:100%;

  }

  .foundation{
    color:#353535;
    padding: 30px 0px 80px;
    .logo{
      text-align: center;
    }
    img{
      width:190px;
      height: 110px;
      margin-bottom: 20px;
    }
    .en .chinaUni .num {
      font-size: 46px;
    }
    .logo p{
      font-size: 14px;
      letter-spacing: 1.5px;
      padding: 0 10px;
      text-align: left;
    }
    .en .logo p {
      font-family: "BarlowCondensed-bold";
      padding: 0;
      text-align: justify;
      line-height: 1.5;
      letter-spacing: 0;
      padding: 0 10px;
    }
    .en p{
      line-height: 1.5;
    }
    .en .right{
      padding-left:0px;
    }
    .f-menu {
      display: none;
      padding-bottom: 120px;
    }
    .west ul li:nth-child(n+3){
      margin-top: 20px;
    }
    /* 幼儿园 */
    h3{
      font-size: 46px;
      color:rgb(34, 195, 173);
      font-weight: bold;
      text-align: center;
      padding-bottom: 30px;
    }
    h4{
      font-size: 28px;
      color:rgb(34, 195, 173);
      font-weight: bold;
      text-align: center;
      padding-bottom: 30px;
    }
  .en h4{
    font-size: 28px;
  }
    .fbanner{
      width: 100vw;
      margin-top: 35px;
    }
    .fbanner img{
      width:100vw;
      height: 72vw;
    }
    .cbanner1{
      width: 100%;
      margin-top: 50px;
    }

    .cbanner1 img{
      width:100vw;
      height: 72vw;
    }
    .ybanner2{
      width:100%;
      margin-top: 50px;

    }
    .ybanner2 img{
      width: 100vw;
      height: 60vw;
    }

    .children{

      padding: 90px 0 0;
    }
    .children-content{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
    }

    .children-content-left{
      width: 100%;
    }
    .en .children-content-left {
      width: 100%;

    }
    .children-content p{
      font-size: 14px;
      letter-spacing: 1.5px;
      text-align: justify;
      padding: 0 10px;
    }
    .en .children-content p{
      font-size: 14px;
      letter-spacing: 0px;
      text-align: justify;
      padding: 0 10px;
      line-height: 1.5;
    }
    .children-content-right{
      width: 100%;
      padding-top: 10px;
      margin-left:0px;
      padding-left: 10px;
      box-sizing: border-box;
      margin-top: 0px;
    }
    .en  .children-content-right{
      width: 100%;
    }
    .margin6{
      margin-left: 0px;
    }
    .children-content-right .num{
      font-size: 46px;
      font-family:"BarlowCondensed-bold";
      color:rgb(53, 53, 53);
      vertical-align: baseline;
    }
    .children-content-right .text{
      font-size: 20px;
      color:rgb(53, 53, 53);
      vertical-align: baseline;
    }
      .carbon{
        padding-top: 100px;
    }

    /* 抗争救灾 */
    .provide{
      margin-top: 60px;
      padding: 0px 10px 0px;
    }
    .provide ul{
      flex-direction: column;
      width: 100%;
      flex-wrap: wrap;
    }

    .provide p{
      font-size: 14px;
      letter-spacing: 1.5px;
      padding-top: 10px;

    }
    .en .provide p{
      letter-spacing: 0;
    }
    .provide-margin{
      margin-top:0px;
    }
    .provide ul li{
      display: flex;
      flex-direction: column;
      width: 100%;
      padding-right: 20px;
      padding-top: 20px;
      box-sizing: border-box;
    }
    .provide ul li:nth-child(2n){
      text-align: left;
      padding-left: 0px;
      padding-right: 0px;
    }
    .en .provide ul li:nth-child(2n){
      text-align: left;
      padding-left: 0px;
      padding-right: 0px;
    }
    .num{

      font-size: 46px;
      color:rgb(34, 195, 173);
      font-family: "BarlowCondensed-bold";
      padding-left:0px;
      padding-bottom: 0px;
      vertical-align: baseline;
    }

    .text{
      font-size: 22px;
      color:rgb(34, 195, 173);
    }
    .detail{
        margin-top: -5px;
          font-size: 22px;
          margin-left: 0px;
          color: #353535;
          display: block;
        }

    .en .detail{
      margin-top: -14px;
    }

    .west ul li:nth-child(2n){
      padding-left: 0px;
      padding-right: 0;
    }
    .west{
      margin-top: 80px;
      padding: 0 10px 0px;
    }
    .west-margin{
      margin-top: 0px;
    }
    .west ul li{
      display: flex;
      flex-direction: column;
      width: 100%;
      padding-right: 0px;
    }
    .west p{
      font-size: 14px;
      letter-spacing: 1.5px;
    }
    .en .west p{
      letter-spacing: 0;
      line-height: 1.5;
    }
    .west ul li:nth-child(2n){
      order:1
    }

    .en .west ul li .left {
      width: 100%;
    }
    .en .west ul li .right {
      width: 100%;
    }

    /* 中国助学 */

    .chinaUni{
      padding-bottom:0px;
      margin-top: 80px;
    }
    .chinaUni-content{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

    }
    .chinaUni-content-right-spandetail{
      font-size: 20px;
      display: block;
      margin-top: -7px;
    }
    .chinaUni-content-left{
      width:100%;
    }
    .chinaUni-content p{
      font-size: 14px;
      letter-spacing: 2px;
      line-height: 1.5;
      padding: 0 10px;
    }
    .en .chinaUni-content p{

      letter-spacing:0px;


    }
    .chinaUni .num{
      font-size: 46px;
      color:rgb(53, 53, 53);
      font-weight: bold;
      text-align: center;
      padding-bottom:0px;
    }
    .chinaUni .text{
      font-size: 20px;
      color:rgb(53, 53, 53);

    }
    .chinaUni-content-right{
      text-align: left;
      width: 100%;
      margin-left:0px;
      padding-left: 10px;
      margin-top: 10px;
      box-sizing: border-box;
    }
    .en .chinaUni-content-right {
      text-align: left;
      width: 100%;
    }

    .chinaUni .banner img{
      width: 100%;
      height: 600px;
    }
    // 百草园
    .herbar {
      margin-top: 80px;
      .herbarimg{
        margin-top: 50px;
          p{
          font-size: 14px;
          letter-spacing: 2px;
          line-height: 1.5;
          padding: 0 10px;
        }
      }
   
    }
    /* 一家人 */
    .family{
      padding-top: 90px;
      margin-top: 0;
    }
    .family p{
      font-size: 14px;
      letter-spacing: 2px;
      line-height: 1.5;
      padding: 0 10px;
    }
    .family p{
      letter-spacing: 0px;
    }

    .source{
     padding-top: 90px;
      margin-top: 0;
      padding-bottom: 0px;
    }
    .source img{
      margin-top: 0px;
      display: block;
      width: 100%;
      height:auto;
    }

    .source p{
      font-size: 14px;
      letter-spacing: 2px;
      line-height: 1.5;
      padding: 0 10px;
    }
    .en .source p{
      letter-spacing: 0;
    }
    .sch{
      margin-top: 90px;
    }
    .sch p {
      font-size: 14px;
      letter-spacing: 2px;
      line-height: 1.5;
      padding: 0 10px;
    }
    .en .sch p{
      letter-spacing: 0;
    }
  }
}

</style>
